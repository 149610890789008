@import 'variables';

html{
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: Wotfard, Arial, serif;
    background: linear-gradient(-13deg, #4e0138 -10%, #00031b 50%);
    color: $fontColor;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;

    @media (min-width: $desktopBreak) {
        font-size: 16px;
    }

    &.app {
        padding-top: 0 !important;
    }

    &.lang-kr {
        font-family: 'Noto Sans KR', Wotfard, Arial, serif;
    }

    &.lang-ja {
        font-family: 'Noto Sans JP', Wotfard, Arial, serif;
    }

    &.lang-zh {
        font-family: 'Noto Sans SC', Wotfard, Arial, serif;
    }

    &.popupOpen {
        overflow: hidden;
    }

    .walletconnect-modal__mobile__toggle a{
        color: black;
    }
}

body, textarea {
    scrollbar-width: thin;
    scrollbar-color: $darkTeal transparent;

    &::-webkit-scrollbar-track {
        background: $darkBlue;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $darkTeal;
        border-radius: 6px;
        border: 2px solid $darkBlue;
    }

    &::-webkit-scrollbar {
        width: 11px;
        background: transparent;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
        border: none;
    }
}

a,
p,
textarea,
select,
input,
button,
svg {
    font-family: inherit;
    color: inherit;
}

.container {
    margin: 0 16px;
    @media (min-width: $desktopBreak) {
        max-width: 960px;
        margin: auto;
    }
    @media (min-width: $bigDesktopBreak) {
        max-width: 1224px;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

p.description {
    font-weight: 300;
    letter-spacing: 0.02em;
    line-height: 22px;
    font-size: 14px;
    margin: 0;

    @media (min-width: 1000px) {
        font-size: 15px;
    }
}

.gradient-text {
    background: linear-gradient(
                    to right,
                    $teal 0%,
                    rgba($teal, 0.4) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    width: max-content;
}
