@import "./variables";



@font-face {
    font-family: "Wotfard";
    src: url("/assets/fonts/wotfard-extralight-webfont.woff2") format("woff2"),
    url("/assets/fonts/wotfard-extralight-webfont.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Wotfard";
    src: url("/assets/fonts/wotfard-regular-webfont.woff2") format("woff2"),
        url("/assets/fonts/wotfard-regular-webfont.ttf") format("truetype");

    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Wotfard";
    src: url("/assets/fonts/wotfard-medium-webfont.woff2") format("woff2"),
        url("/assets/fonts/wotfard-medium-webfont.ttf") format("truetype");

    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Wotfard";
    src: url("/assets/fonts/wotfard-semibold-webfont.woff2") format("woff2"),
        url("/assets/fonts/wotfard-semibold-webfont.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

h2.section-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    display: inline-block;
    margin: 0;

    &.gradient {
        background: linear-gradient(to right, #ee4492 0%, #574ea0 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (min-width: $desktopBreak) {
        font-size: 20px;
        line-height: 25px;
    }
}

h3.large-section-title {
    margin: 8px 0 0;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;

    @media (min-width: $desktopBreak) {
        font-size: 60px;
        line-height: 74px;
    }

    &.gradient {
        background: linear-gradient(to left, #ee4492 0%, #574ea0 80%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

h3.section-title {
    margin: 8px 0 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    @media (min-width: $desktopBreak) {
        font-size: 40px;
        line-height: 48px;
    }
}

p,
.f16 {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;

    @media (min-width: $desktopBreak) {
        font-size: 16px;
    }
}