:root{
    --pink: #D84594;
    --gold: #F3BA2F;
    --pink-secondary: #ee4492;
    --font-color:#fff;
    --light-purple:#574EA0;
    --light-blue:#AEE7FF;
}

$black: #000;
$dark: #021117;

$gray: #b7b7b7;

$fontColor: #fff;

$teal: #00ffff;
$teal2: #01d8d6;
$green: #00ffd1;
$darkBlue: #09163b;
$darkTeal: #073a4d;
$purple: #5200ff;
$lightPurple: #574EA0;
$lightBlue: #AEE7FF;

$pink:#D84594;

$formColor: #02cdd2;


$gradientGreen: linear-gradient(94.56deg, $teal 10.37%, $green 90.35%);
$gradientPurple: linear-gradient(9.01deg, rgba($teal, 0.3) -45.61%, rgba($purple, 0.2) 102.05%);
$gradientPurple2: linear-gradient(103.25deg, rgba($teal, 0.15) 0%, rgba($purple, 0.1) 100%);

$gradientPurpleBlue: linear-gradient(93.88deg, #cd7cff 16.21%, rgba(0, 255, 255, 0.4) 56.94%);

$binaryPattern: url("../../svg/bg/binary-pattern-tile.svg");

$border: 2px;

$navigationHeight: 85px;
$mobileNavigationHeight: 56px;

$tabletBreak: 750px;
$desktopBreak: 1000px;
$bigDesktopBreak: 1300px;
