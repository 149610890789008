@import './fonts.scss';
@import './globals.scss';

:root {
  --toastify-color-success: #47F490;
  --toastify-color-error: #FF4400E5;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-success: #47F490;
  --toastify-icon-color-error: #FF4400E5;
  --toastify-color-progress-error: #FF4400E5;
  --toastify-color-progress-success: #47F490;
  --toastify-text-color-dark: #fff;
  --toastify-color-dark: linear-gradient(0deg, #00031B, #00031B), #FFFFFF
}

.Toastify__toast {
  border: 1px solid rgba(147, 140, 188, 0.5);
  box-shadow: 0 2px 24px rgba(87, 78, 160, 0.3);
  border-radius: 12px;
  padding: 16px 24px 24px;
  width: 100%;

  svg {
    width: 24px;
    height: 24px;
  }
}

.Toastify__toast-body {
  width: 100%;
}

.Toastify__toast-body > div:not(.Toastify__toast-icon) {
  width: 100%;
}